import { Grid } from '@chakra-ui/core'
import { rem } from 'design'
import { ModalHeader } from 'design/components/Modal/Modal'
import ModalCard from 'design/components/ModalCard/ModalCard'
import { Body, H2 } from 'design/components/Typography/Typography'
import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import { TEST_ID } from '~/constants/cypress'
import { formatPriceFromCents } from '~/utils'
import BoxVariants from '~/utils/boxVariants'
import { humanizeBoxSize } from '~/utils/humanize'
import StopMySubscriptionButton from '~/components/CancelFlowPanels/StopMySubscriptionButton/StopMySubscriptionButton'
import type * as Types from '~/components/CancelFlowPanels/ActionPanels/PanelChangeSize/PanelChangeSize.types'

function PanelChangeSizeUI({
  panel,
  box,
  handleSubscriptionUpdate,
  confirmCtaText,
}: Types.ChangeSizeUIProps) {
  return (
    <PanelLayout>
      <ModalHeader pb={rem(8)} variant="bare">
        {panel.headline}
      </ModalHeader>

      <Body pb={rem(16)}>{panel.subhead}</Body>

      <ModalCard
        alignItems="center"
        d="flex"
        justifyContent="center"
        maxW="100%"
        mb={rem(24)}
        minHeight={rem(200)}
      >
        {box.type !== 'cst' && (
          <H2 color="bb.spicedCrimson" pb={rem(10)}>
            {box.name}
          </H2>
        )}

        {/* this renders the appropriate icon set */}
        {BoxVariants[box.type].render()}

        <Body>{humanizeBoxSize(box.size)}</Body>
        {box.poundage && box.price ? (
          <>
            <Body>{box.poundage}</Body>
            <Body color="bb.spicedCrimson">
              {formatPriceFromCents(box.price)}
            </Body>
          </>
        ) : null}
      </ModalCard>

      <Grid gap={rem(16)} gridTemplateColumns="1fr">
        <ModalItemSmall
          data-cy={TEST_ID.CANCEL_FLOW_SAVE}
          onClick={handleSubscriptionUpdate}
        >
          {confirmCtaText}
        </ModalItemSmall>
        <StopMySubscriptionButton />
      </Grid>
    </PanelLayout>
  )
}

export default PanelChangeSizeUI
