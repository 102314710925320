import React from 'react'
import useBoxSizes from '~/hooks/useBoxSizes'
import useBoxTypes from '~/hooks/useBoxTypes'
import type * as Types from '../PanelChangeSize.types'

// A recommendation is provided based on the users current subscription
// based on type / size - to show the upgrade/downgrade option we
// need to hydrate a recommended box based on data from
// boxTypes and boxSizes

const useHydrateRecommendation: Types.HydrateRecommendationHookFunction = function (
  recommendation
) {
  const [isHydrated, setIsHydrated] = React.useState(false)
  const [recommendedSize, setRecommendedSize] = React.useState(null)
  const [recommendedType, setRecommendedType] = React.useState(null)

  const { data: typeDefinitions = [], status: typeStatus } = useBoxTypes()
  const { data: sizeDefinitions = [], status: sizeStatus } = useBoxSizes(
    recommendation.type
  )

  React.useEffect(() => {
    if (
      typeStatus === 'loading' ||
      sizeStatus === 'loading' ||
      isHydrated == true
    ) {
      return
    }
    setIsHydrated(true)
    setRecommendedSize(
      (sizeDefinitions || []).find((def) => def.size === recommendation.size)
    )
    setRecommendedType(
      (typeDefinitions || []).find((def) => def.type === recommendation.type)
    )
  }, [
    isHydrated,
    recommendation,
    sizeDefinitions,
    sizeStatus,
    typeDefinitions,
    typeStatus,
  ])

  return [
    isHydrated,
    {
      ...recommendation,
      ...recommendedSize,
      ...recommendedType,
    },
  ]
}

export default useHydrateRecommendation
