import React from 'react'
import type * as TYPES from '../PanelChangeSize.types'

const useRecommendedLargerBox: TYPES.RecommendationHookFunction = function (
  size,
  type,
  items
) {
  return React.useMemo(() => {
    const combo = { size, type, items }
    // the algorithm is in this ticket's acceptance criteria: https://nbox-341.atlassian.net/browse/ENG-495
    switch (true) {
      case type === 'cst' && size === 'classic': {
        combo.size = 'big'
        break
      }

      case (type === 'all_beef' ||
        type === 'beef_and_chicken' ||
        type === 'beef_and_pork' ||
        type === 'beef_chicken_pork') &&
        size === 'classic': {
        combo.size = 'big'
        break
      }

      case (type === 'all_beef' ||
        type === 'beef_and_chicken' ||
        type === 'beef_and_pork' ||
        type === 'beef_chicken_pork') &&
        size === 'big': {
        combo.size = 'big'
        combo.type = 'cst'
        break
      }

      case type === 'chef_value': {
        combo.type = 'beef_chicken_pork'
        combo.size = 'big'
        break
      }

      case type === 'basic_beef_chicken_pork': {
        combo.type = 'beef_chicken_pork'
        break
      }
    }

    return combo
  }, [size, type, items])
}

export default useRecommendedLargerBox
