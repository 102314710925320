import { useNavigate } from '@reach/router'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { navigate as gatsbyNavigate } from 'gatsby'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import { trackCancellationSave } from '~/analytics/events'
import PanelLayout from '~/components/CancelFlowModals/PanelCancelLayout'
import type * as Types from '~/components/CancelFlowPanels/ActionPanels/PanelChangeSize/PanelChangeSize.types'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import { SubscriptionContext } from '~/context/subscription'
import { mutateSubscription } from '~/hooks/mutateSubscription'
import { LoadingPanel } from '../../LoadingPanel/LoadingPanel'
import PanelChangeSizeUI from './PanelChangeSize.ui'
import useRecommendedSmallerBox from './hooks/useRecommendedSmallerBox'
import useRecommendedLargerBox from './hooks/useRecommendedLargerBox'
import useHydrateRecommendation from './hooks/useHydrateRecommendation'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'

const PanelChangeSize: React.FC<CancelPanelProps> = ({
  handleModalClose,
  panel,
}) => {
  const change: Types.ChangeType =
    panel.cancelSaveComponent === 'Change Size - Decrease'
      ? 'decrease'
      : 'increase'
  const recommender =
    change === 'increase' ? useRecommendedLargerBox : useRecommendedSmallerBox
  const navigate = useNavigate()
  const { subscription } = React.useContext(SubscriptionContext)
  const showToast = React.useContext(ToastContext)
  const [updateSubscription] = mutateSubscription('cancel_flow')
  const [submitting, setSubmitting] = React.useState(false)
  const [
    checkingForRedirects,
    setCheckingForRedirects,
  ] = React.useState<boolean>(true)
  const { getHistoryEntry } = React.useContext(ReachRouterHistoryContext)
  const { size, type, items } = subscription.box

  // Generated a recommendation based on current subscription
  const recommendation = recommender(size, type, items)

  // Hydrate the recommendation
  const [isHydrated, hydratedBox] = useHydrateRecommendation(recommendation)

  // Handle redirects
  React.useEffect(() => {
    if (change === 'decrease') {
      // if we don't have a valid recommendation, forward to the lengthen frequency frame
      if (recommendation.size === size && recommendation.type === type) {
        /**
         * If already on the longest frequency, push to delay
         * frame instead.
         */
        if (subscription.interval === 'every_2_months') {
          navigate('/delay-prompt', { replace: true })
          return
        }

        navigate('/change-frequency-cant-afford', { replace: true })
        return
      }
    } else {
      /**
       * if the current subscription interval is
       * >2 weeks, push there instead
       */
      if (subscription.interval !== 'every_14_days') {
        navigate('/change-frequency-not-enough', { replace: true })
        return
      }

      /**
       * if we don't have a valid recommendation,
       * forward to the cancel confirm page
       */
      if (recommendation.size === size && recommendation.type === type) {
        navigate('/are-you-sure', { replace: true })
        return
      }
    }
    setCheckingForRedirects(false)
  }, [navigate, recommendation, subscription.interval, size, type, change])

  const redirectToCustomize = React.useCallback(() => {
    /**
     * If moving into a custom box or a different size custom box, push into customize flow.
     */
    if (recommendation.type === 'cst') {
      gatsbyNavigate(
        `/account/customize-your-box?box-size=${recommendation.size}`
      )
      trackCancellationSave(
        CANCEL_SAVE_ACTIONS.CHANGE_PLAN_FROM_CANCEL_FLOW,
        getHistoryEntry(1).pathname,
        getHistoryEntry(2).state.text
      )
      showToast('warning', {
        children:
          'Your subscription has not been updated yet, please make your selections and press the "Save" button.',
      })
    }
  }, [getHistoryEntry, recommendation.size, recommendation.type, showToast])

  const performSubscriptionUpdate = React.useCallback(() => {
    setSubmitting(true)
    updateSubscription(
      {
        ...subscription,
        box: {
          ...subscription.box,
          ...recommendation,
        },
      },
      {
        onSuccess: () => {
          showToast('success', {
            children: 'Success! Your box has been updated.',
          })
          trackCancellationSave(
            CANCEL_SAVE_ACTIONS.CHANGE_PLAN_FROM_CANCEL_FLOW,
            getHistoryEntry(1).pathname,
            getHistoryEntry(2).state.text
          )
        },
        onError: () => {
          showToast('error', {
            children:
              'There was an error updating your subscription. Please try again.',
          })
        },
        onSettled: () => {
          setSubmitting(false)
          handleModalClose()
        },
      }
    )
  }, [
    getHistoryEntry,
    handleModalClose,
    recommendation,
    showToast,
    subscription,
    updateSubscription,
  ])

  // avoids flickering while we check if a redirect is needed during mount
  if (checkingForRedirects) return <PanelLayout />

  return !isHydrated || submitting ? (
    <LoadingPanel />
  ) : (
    <PanelChangeSizeUI
      box={hydratedBox}
      confirmCtaText={
        change === 'increase'
          ? 'Yes, switch to the bigger box'
          : 'Yes, switch to the smaller box'
      }
      handleModalClose={handleModalClose}
      handleSubscriptionUpdate={
        recommendation.type === 'cst'
          ? redirectToCustomize
          : performSubscriptionUpdate
      }
      panel={panel}
    />
  )
}

export default PanelChangeSize
