import { useQuery } from 'react-query'
import { CACHE_KEY_BOX_SIZES } from '~/bb-api/constants'
import { GET_BOX_SIZES } from '~/bb-api/endpoints'
import { BoxSizeDefinition, BoxType } from '~/bb-api/schemata'
import axios from '~/utils/axios'

export default function useBoxSizes(boxType: BoxType) {
  const endPoint = GET_BOX_SIZES(boxType)

  return useQuery(
    [CACHE_KEY_BOX_SIZES, boxType],
    () =>
      axios
        .get<{ data: BoxSizeDefinition[] }>(endPoint)
        .then((response) =>
          response.data.data.sort((a, b) => (b.price > a.price ? -1 : 1))
        ),
    {
      enabled: !!boxType,
      staleTime: Number.POSITIVE_INFINITY, // does not need to expire
    }
  )
}
